import Analytics from 'factory/modules/Analytics';
import HK from 'factory/modules/HK';
import React, { Component } from 'react';
import TBSLoader from '../../Pages/TrueBasics/TBUtils/TBSLoader';

import { cartAppliedOfferAction, cartOfferAction, cartOrderSummaryAction, cartPackAction, cartVariantAction } from 'components/Cart/action';
import { headerMenuAction, userRoleAction } from 'components/common/header/action';
import { CARTAction } from 'components/common/header/action.js';
import { isLoginPopupAction } from 'components/common/login/action';
import { codAllowedAction, paymentPrepareAction } from 'components/Payment/action';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { bindActionCreators } from 'redux';

import { headerTextAction } from 'components/common/header/SingleLineHeader/action';
import IntersectionObserver from 'components/common/IntersectionObserver';
import QuantitySelector from 'components/Pdp/common/quantitySelector/QuantitySelector';
import Itrack from 'utility/Itrack';
import { CartUtility } from '../cartUtility';
import BogoItem from './BogoItem';
import FreebieItem from './FreebieItem';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from 'static/css/cartitem/cartItem.module.scss';
import UniversalAnalytics from 'factory/modules/UniversalAnalytics';
import ConfirmModal from 'components/Cart/common/ConfirmModal/ConfirmModal.js';
import HyperLocalDeliveryWidgit from 'components/common/hyperLocalDelivery/hyperLocalDeliveryWidgit';
import { __isCallBackExist, __isMobile } from 'factory/modules/HK_service';
// import FlashBar from '../../Pdp/common/FlashBar';
// import LoyaltyIcon from 'utility/templates/Generic/LoyaltyIcon';
class CartItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            itemInp: this.props.isPack ? this.props.pack.qty : this.props.data.qty,
            isDeletePopup: false,

        };
        this.membershipPrdct = HK.checkMembershipProduct(props.data);
    }
    componentDidUpdate(prevProps) {
        if (HK.getSearchParmas().cartId) {
            if (prevProps.data.qty !== this.props.data.qty) {
                this.setState({ itemInp: this.props.data.qty });
            }
        }
    }
    componentDidMount() {
        HK.isMobile() && HK.chatIcon(false, false);
    }
    updateCart(qty) {
        let reqObj;
        if (parseInt(qty, 10) !== 0) {
            this.setState({
                showLoader: true
            });
            if (this.props.isPack) {
                const storeVariantIdsInPackStr= this.props.pack.variants && this.props.pack.variants.length>0 && this.props.pack.variants.map((e) => { return e.sv_id; }).join(',');
                reqObj = {
                    method: "post",
                    url: HK.config.APIS.cart.cartPackUpdate,
                    data: {
                        "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                        "packId": this.props.pack.id,
                        "vendorId": this.props.pack.vendorId,
                        "quantity": qty,
                        "storeVariantIdsInPackStr": storeVariantIdsInPackStr
                    }
                };
            } else {
                reqObj = {
                    method: "post",
                    url: HK.config.APIS.cart.cartUpdate,
                    data: {
                        "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                        "storeVariantId": this.props.data.sv_id,
                        "vendorId": this.props.data.vendorId,
                        "quantity": qty
                    }
                };
            }
            // Updating Quantity for buy now order summary page.
            const cartId = HK.getSearchParmas().cartId;
            if (cartId) {
                reqObj.data.cartId = cartId;
            }

            HK.ajaxCall(reqObj).then((res) => {
                if (res && res.data && res.data.results && !res.data.results.exception) {
                    this.props._cartVariantAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartVar : []);
                    this.props._cartPackAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartPacks : []);
                    this.props._cartOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartOffers : []);
                    this.props._cartAppliedOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.appOfrDtl : null);
                    Analytics.Scope().cartUpdate(res.data.results, reqObj.data.storeVariantIdsInPackStr || reqObj.data.storeVariantId);
                    this.props._CARTAction(res.data.results);

                    this.updateCartIcon();
                    if (window.payTp === "40") {
                        this.preparePaymentCall();
                    } else {
                        this.props._cartOrderSummaryAction(res.data.results.cartPricing);
                        this.setState({
                            showLoader: false
                        });
                    }


                    let cartData, cartPackData, itemNameData, priceData, offerPriceData, quantityData; //itemPositionData

                    if (res.data.results.cartPricing && res.data.results.cartPricing.cartVar.length > 0) {
                        cartData = HK.getCartDataForClickEvent(res.data.results.cartPricing).split("||");
                        itemNameData = cartData[0];
                        priceData = parseInt(cartData[1]);
                        offerPriceData = parseInt(cartData[2]);
                        quantityData = parseInt(cartData[3]);
                    }

                    if (res.data.results.cartPricing && res.data.results.cartPricing.cartPacks.length > 0) {
                        cartPackData = HK.getCartPackDataForClickEvent(res.data.results.cartPricing).split("||");

                        itemNameData = itemNameData + "," + cartPackData[0];
                        priceData = priceData + parseInt(cartPackData[1]);
                        offerPriceData = offerPriceData + parseInt(cartPackData[2]);
                        quantityData = quantityData + parseInt(cartPackData[3]);
                    }

                    if (this.props && this.props._cartOrderSummaryReducer && this.props._cartOrderSummaryReducer.promoDisc > 0) {
                        offerPriceData = offerPriceData - this.props._cartOrderSummaryReducer.promoDisc;
                    }

                    const clickStream = {
                        widgetName: 'Update Quantity',
                        eventName: "UPDATE_QUANITY",
                        itemName: itemNameData,
                        price: +priceData,
                        offerprice: +offerPriceData,
                        quantity: +quantityData
                    };
                    HK.triggerComponentClickEvent(clickStream);
                } else {
                    HK.errorPopup(res.data && res.data.results && res.data.results.msgs[0]);
                    HK.reportErrorToDashboard({
                        stackTrace: new Error().stack,
                    });

                    // HK.errorPopup("Something went wrong, please try again.");
                    if (this.refs.serverError) {
                        this.refs.serverError && this.refs.serverError.classList.add('active');
                        this.refs.serverError.innerHTML = res && res.data && res.data.results.msgs[0];
                        this.refs.stepInp.value = this.props.isPack ? this.props.pack.qty : this.props.data.qty;
                    }
                    this.setState({
                        showLoader: false
                    });
                }
            }).catch((err) => {
                console.log(err);
                HK.errorPopup("Something went wrong, please try again.");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
                this.setState({
                    showLoader: false
                });
            });
        }
    }
    /* payment call */
    preparePaymentCall = () => {
        let addressId;
        const cartId = HK.getSearchParmas().cartId;
        const selectedAddressId = HK.getSearchParmas().selectedAddressId;
        if (this.props.selectedAddressId) {
            addressId = this.props.selectedAddressId;
        } else {
            addressId = selectedAddressId;
        }
        let reqObj = {
            method: "get",
            url: HK.config.APIS.payment.preparePayment.replace('ADDRESS_ID', addressId).replace('PAYMENT_TYPE_ID', 40),
        };
        if (cartId) {
            reqObj.header = { crtId: cartId };
        }
        HK.ajaxCall(reqObj).then((res) => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                this.props._cartOrderSummaryAction(res.data.results.orderPricing);
                this.props._codAllowedAction({ isCodAllowed: res.data.results.cod_allowed, codMsg: res.data.results.cod_disable_msg });
                this.props._paymentPrepareAction(res.data.results);
                this.setState({ showLoader: false });
            } else {
                HK.errorPopup(res.data.results.msgs[0]);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
                this.setState({ showLoader: false });
            }
        }).catch((err) => {
            this.setState({ showLoader: false });
            console.log(err);
            HK.errorPopup("Something went wrong, please try again");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    };
    updateCartIcon = () => {
        HK.ajaxCall({
            url: HK.config.APIS.cart.summary,
            method: "GET"
        }).then(res => {
            const _res = res && res.data && res.data.results;
            if (_res && !_res.exception) {
                // this.props._headerText("Your Cart (" + (res.data.results.noItemsInCart) + ")");
                this.props._headerText("Cart");

                if (res.data.results.noItemsInCart == 0) {
                    this.props._headerMenuAction(null);
                    this.props._userRoleAction(_res.hkUserLoyaltyDetailsDto && _res.hkUserLoyaltyDetailsDto.role);
                    if (!this.props.desktopCartMenu) {
                        // this.props.history.push("/cart/Cart.action"); //todomb
                        window.location.href = "/cart/Cart.action";
                    }
                }
            }
        });
    };
    removeCart() {
        // window.location.reload()
        if (this.props.quickBuy && this.props.dataIndex == 0 && !this.props.comboItemLength) {
            return;
        } else {
            let reqObj;
            this.setState({
                showLoader: true
            });
            if (this.props.isPack) {
                reqObj = {
                    method: "post",
                    url: HK.config.APIS.cart.cartPackRemove,
                    data: {
                        "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                        "packSrcEntityIdToRemove": this.props.pack.pckKey
                    }
                };
            } else {
                reqObj = {
                    method: "post",
                    url: HK.config.APIS.cart.cartRemove,
                    data: {
                        "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                        "storeVariantsToRemove": this.props.data.sv_id
                    }
                };
            }
            // Updating Quantity for buy now order summary page.
            const cartId = HK.getSearchParmas().cartId;
            if (cartId) {
                reqObj.data.cartId = cartId;
            }
            HK.ajaxCall(reqObj).then((res) => {
                if (res && res.data && res.data.results && !res.data.results.exception) {
                    this.setState({
                        showLoader: false
                    });
                    const clickStream = {
                        widgetName: 'Cart Remove',
                        eventName: "CART_REMOVE",
                        itemName: this.props.data && this.props.data.sv_nm,
                        id: this.props.data && this.props.data.sv_id,
                        brand: this.props.data && this.props.data.brand,
                        category: this.props.data && this.props.data.catName,
                        price: this.props.data && this.props.data.totOffPr,
                        urlFragment: this.props.data && this.props.data.urlFragment
                    };
                    UniversalAnalytics.CART_REMOVE(this.props.isPack ? this.props.pack : this.props.data, this.props.isPack);
                    HK.triggerComponentClickEvent(clickStream);
                    HK.triggerComponentViewEvent(clickStream);
                    this.props._cartOrderSummaryAction(res.data.results.cartPricing);
                    this.props._cartVariantAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartVar : []);
                    this.props._cartPackAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartPacks : []);
                    this.props._cartOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartOffers : []);
                    this.props._cartAppliedOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.appOfrDtl : null);
                    Analytics.Scope().cartUpdate(res.data.results, reqObj.data.storeVariantsToRemove || reqObj.data.packSrcEntityIdToRemove);
                    this.props._CARTAction(res.data.results);

                    this.updateCartIcon();
                    CartUtility.removeFromCartEvent(this.props.data);
                    setTimeout(HK.toast("Product Removed from Cart"), 1000);
                } else {
                    HK.errorPopup("Something went wrong, please try again.");
                    HK.reportErrorToDashboard({
                        stackTrace: new Error().stack,
                    });
                    this.setState({
                        showLoader: false
                    });
                }
            }).catch((err) => {
                console.log(err);
                HK.errorPopup("Something went wrong, please try again.");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
                this.setState({
                    showLoader: false
                });
            });
        }
    }
    moveToWishList() {
        if (HK.loggedIn()) {
            let reqObj;
            this.setState({
                showLoader: true
            });
            if (!this.props.isPack) {
                reqObj = {
                    method: "post",
                    url: HK.config.APIS.wishlist.add,
                    data: {
                        "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                        "svId": this.props.data.sv_id,
                        "cart": true,
                        "pack": false
                    }
                };
            } else {
                reqObj = {
                    method: "post",
                    url: HK.config.APIS.wishlist.add,
                    data: {
                        "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                        "svId": this.props.pack.id,
                        "cart": true,
                        "pack": true,
                        "cartPrdId": this.props.pack.pckKey
                    }
                };
            }
            HK.ajaxCall(reqObj).then((res) => {
                if (res && res.data && res.data.results && !res.data.results.exception) {
                    this.getCartItem();
                    Analytics.Scope().cartUpdate(res.data.results);
                    this.updateCartIcon();
                    HK.toast('Product Moved To Wishlist', 3000, 'heart-icon show');
                    const clickStream = {
                        widgetName: 'MOVE To Wishlist',
                        eventName: "MOVE_TO_WISHLIST",
                        itemName: this.props.data && this.props.data.sv_nm,
                        id: this.props.data && this.props.data.sv_id,
                        brand: this.props.data && this.props.data.brand,
                        category: this.props.data && this.props.data.catName,
                        price: this.props.data && this.props.data.totOffPr,
                        urlFragment: this.props.data && this.props.data.urlFragment
                    };
                    HK.triggerComponentClickEvent(clickStream);
                    const varData = {
                        offerPrice: this.props.data?.totOffPr,
                        name: this.props.data?.sv_nm,
                        quantity: this.props.data?.qty,
                        imageUrl: this.props.data?.primaryImage,
                        ...this.props.data,
                    };
                    Analytics.dataLayerForCartUpdate(reqObj.data.svId, varData, this.props.isPack, 'reactWishListEvent');
                } else {
                    HK.errorPopup("Something went wrong, please try again.");
                    HK.reportErrorToDashboard({
                        stackTrace: new Error().stack,
                    });
                    this.setState({
                        showLoader: false
                    });
                }
            }).catch((err) => {
                console.log(err);
                HK.errorPopup("Something went wrong, please try again.");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
                this.setState({
                    showLoader: false
                });
            });
        } else {
            this.props._isLoginPopupAction({
                isShow: true,
                activeTab: "login"
            });
        }
    }
    getCartItem() {
        let reqO = {
            method: "get",
            url: HK.config.APIS.cart.cartGet
        };
        HK.ajaxCall(reqO).then((res) => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                this.props._cartOrderSummaryAction(res.data.results.cartPricing);
                this.props._cartVariantAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartVar : []);
                this.props._cartPackAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartPacks : []);
                this.props._cartOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartOffers : []);
                this.props._cartAppliedOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.appOfrDtl : null);
                this.props._CARTAction(res.data.results);
                this.setState({
                    showLoader: false
                });
                Analytics.Scope().cartUpdate(res.data.results);
                this.updateCartIcon();
            } else {
                HK.errorPopup("Something went wrong, please try again");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
        }).catch((err) => {
            console.log(err);
            HK.errorPopup("Something went wrong, please try again");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    }
    inpChangHandle(e) {
        if (!this.refs.stepInp.value || (/^[0-9]+$/).test(e.target.value)) {
            this.setState({
                itemInp: e.target.value
            });
        }
    }
    onBlurInp() {
        if (this.refs.stepInp.value && this.refs.stepInp.value !== this.props.data.qty && parseInt(this.refs.stepInp.value, 10) !== 0) {
            this.updateCart(this.refs.stepInp.value, HK.getSearchParmas().cartId);
        } else {
            this.refs.stepInp.value = this.props.isPack ? this.props.pack.qty : this.props.data.qty;
        }
    }
    applyBxgyOffer(data) {
        let reqObj;
        this.setState({
            showLoader: true
        });
        if (this.props.isPack) {
            const storeVariantIdsInPackStr= this.props.pack.variants && this.props.pack.variants.length>0 && this.props.pack.variants.map((e) => { return e.sv_id; }).join(',');
            reqObj = {
                method: "post",
                url: HK.config.APIS.cart.cartPackUpdate,
                data: {
                    "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                    "packId": this.props.pack.id,
                    "vendorId": this.props.pack.vendorId,
                    "quantity": data.offerTrigger.minQty,
                    "storeVariantIdsInPackStr": storeVariantIdsInPackStr
                }
            };
        } else {
            reqObj = {
                method: "post",
                url: HK.config.APIS.cart.cartUpdate,
                data: {
                    "userId": HK.loggedIn() || localStorage.getItem('isUserLoggedIn'),
                    "storeVariantId": this.props.data.sv_id,
                    "vendorId": this.props.data.vendorId,
                    "quantity": data.offerTrigger.minQty
                }
            };
        }
        const cartId = HK.getSearchParmas().cartId;
        if (cartId) {
            reqObj.data.cartId = cartId;
        }
        HK.ajaxCall(reqObj).then((res) => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                if (res.data.results.cart_changed) {
                    HK.errorPopup(res.data.results.msgs[0]);
                    HK.reportErrorToDashboard({
                        stackTrace: new Error().stack,
                    });
                } else {
                    reqObj = {
                        method: "post",
                        url: HK.config.APIS.cart.applyBxgyOffer,
                        data: {
                            userId: HK.loggedIn() ? parseInt(HK.loggedIn(), 10) : parseInt(localStorage.getItem('isUserLoggedIn'), 10),
                            storeVariantId: this.props.data.sv_id,
                            userDisabled: false
                        }
                    };
                    const cartId = HK.getSearchParmas().cartId;
                    if (cartId) {
                        reqObj.data.cartId = cartId;
                    }
                    HK.ajaxCall(reqObj).then((res) => {
                        if (res && res.data && res.data.results && !res.data.results.exception) {
                            this.setState({
                                showLoader: false
                            });
                            this.props._cartOrderSummaryAction(res.data.results.cartPricing);
                            this.props._cartVariantAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartVar : []);
                            this.props._cartPackAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartPacks : []);
                            this.props._cartOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartOffers : []);
                            this.props._cartAppliedOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.appOfrDtl : null);
                            Analytics.Scope().cartBogoApplied(data.nm);
                        } else {
                            HK.errorPopup(res.data.results.msgs[0]);
                            HK.reportErrorToDashboard({
                                stackTrace: new Error().stack,
                            });
                            this.setState({
                                showLoader: false
                            });
                        }
                    }).catch((err) => {
                        console.log(err);
                        HK.errorPopup(res && res.data && res.data.results && res.data.results.msgs[0]);
                        HK.reportErrorToDashboard({
                            stackTrace: new Error().stack,
                            errorMessage: err
                        });
                        this.setState({
                            showLoader: false
                        });
                    });
                }
                localStorage.setItem("bogoData", true);
            } else {
                HK.errorPopup("Something went wrong, please try again.");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
                this.setState({
                    showLoader: false
                });
            }
        }).catch((err) => {
            console.log(err);
            HK.errorPopup("Something went wrong, please try again.");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
            this.setState({
                showLoader: false
            });
        });
    }
    removeBxgyOffer() {
        Analytics.Scope().cartBogoRemoved(this.props._cartOrderSummaryReducer.appOfrDtl.nm);
        this.setState({
            showLoader: true
        });
        let reqObj = {
            method: "get",
            url: HK.config.APIS.cart.removeBxgyOffer
        };
        const cartId = HK.getSearchParmas().cartId;
        if (cartId) {
            reqObj.header = {
                crtId: cartId
            };
        }
        HK.ajaxCall(reqObj).then((res) => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                localStorage.removeItem("bogoData");
                this.setState({
                    showLoader: false
                });
                this.props._cartOrderSummaryAction(res.data.results.cartPricing);
                this.props._cartVariantAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartVar : []);
                this.props._cartPackAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartPacks : []);
                this.props._cartOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartOffers : []);
                this.props._cartAppliedOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.appOfrDtl : null);

            } else {
                HK.errorPopup("Something went wrong, please try again.");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
                this.setState({
                    showLoader: false
                });
            }
        }).catch((err) => {
            console.log(err);
            HK.errorPopup("Something went wrong, please try again.");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
            this.setState({
                showLoader: false
            });
        });
    }
    renderBxgyOffer(data) {
        return (
            <BogoItem
                data={data}
                removeBxgyOffer={this.removeBxgyOffer.bind(this, data)}
                parentState={this.state}
                onDecrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty - 1 : this.props.data.qty - 1))}
                onIncrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty + 1 : this.props.data.qty + 1))}
                removeCart={this.removeCart.bind(this)}
                parentProps={this.props}
                desktopCartMenu={this.props.desktopCartMenu}
            />
        );
    }
    renderFreebie = (data) => {
        return (
            data &&
            data.length > 0 &&
            <div className={convertToModule(styles, "freebie__list")}>
                {
                    data.map((freebie, index) => {
                        return (
                            <FreebieItem data={freebie} key={index} />
                        );
                    })
                }
            </div>
        );
    };

    triggerItemClickEvent = () => {
        const eventData = {
            widgetName: 'Product Click',
            eventName: "PRODUCT_CLICK",
            itemName: this.props.data && this.props.data.sv_nm,
            landingUrl: this.props.data && this.props.data.urlFragment,
            id: this.props.data && this.props.data.sv_id,
            brand: this.props.data && this.props.data.brand,
            category: this.props.data && this.props.data.catName,
            price: this.props.data && this.props.data.totMrp,
            offerPrice: this.props.data && this.props.data.totOffPr,
        };
        HK.triggerComponentClickEvent(eventData);
    };

    footerGenerator = () => {
        return (
            <div className={convertToModule(styles, 'combo-footer')}>
                <div className={convertToModule(styles, 'left')}>
                    <button className={convertToModule(styles, 'cart-wishlist')} onClick={this.moveToWishList.bind(this)} >
                        <img src={HK.getImage('/checkout/mweb-heart.svg')} alt="wishlist button" />
                        <span>Add to Wishlist</span>
                    </button>
                </div>
                {!HK.isExpressCheckout() && !HK.checkMembershipProduct(this.props.data) && <div className={convertToModule(styles, "right")}>
                    <QuantitySelector quantity={this.state.itemInp} hideLabel
                        onDecrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty - 1 : this.props.data.qty - 1))}
                        onIncrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty + 1 : this.props.data.qty + 1))}
                        removeCart={this.removeCart.bind(this)}
                        quickBuy={this.props.quickBuy}
                        dataIndex={this.props.dataIndex}
                        comboItemLength={this.props.comboItemLength}
                    />
                </div>
                }
            </div>
        );
    };

    handleDeletePopup = (flag) => {
        this.setState({
            isDeletePopup: flag
        });
    };

    getAttributeStringValue = (value) => {
        if (value) {
            return value;
        } else {
            return '';
        }
    };
    handleCallBackTatCall = () => {
        __isCallBackExist(this.props.cartCall) && this.props.cartCall();
    };
    render() {
        const specialOfferID = (this.props._cartAppliedOfferReducer && this.props._cartAppliedOfferReducer.offerActions && this.props._cartAppliedOfferReducer.offerActions[0] && this.props._cartAppliedOfferReducer.offerActions[0].includedVariants && this.props._cartAppliedOfferReducer.offerActions[0].includedVariants[0]) || null;
        const isMobile = __isMobile();
        //const bogoFl = localStorage.getItem("bogoData");
        return (
            this.props.desktopCartMenu ?
                <IntersectionObserver className={convertToModule(styles, this.props.isCombo ? "cart-item isCombo" : "cart-item")}>
                    <div className={convertToModule(styles, "cartitem-list")}>
                        <div className={convertToModule(styles, "cart-image")}>
                            {
                                this.props.menuActual ?
                                    <div className={convertToModule(styles, "cart-image-side-menu")}>
                                        <img name="tumbnail" src={this.props.data.pr_img && this.props.data.pr_img.t_link && this.props.data.pr_img.t_link.replace('http://', 'https://')} alt={this.props.data.pr_img && this.props.data.pr_img.alt} className={convertToModule(styles, "prod-image")} />
                                    </div>
                                    :
                                    <img name="tumbnail" src={this.props.data.pr_img && this.props.data.pr_img.t_link && this.props.data.pr_img.t_link.replace('http://', 'https://')} alt={this.props.data.pr_img && this.props.data.pr_img.alt} className={convertToModule(styles, "prod-image")} />
                            }
                            {(!this.props.comboItem || (this.props.isCombo && this.props.firstItem)) &&
                                <div className={convertToModule(styles, this.props.isCombo ? "cart-item-delete combo-delete-icon" : "cart-item-delete")} title="Remove" onClick={this.removeCart.bind(this)} >
                                    <img src={HK.getImage("/checkout/delete-icon-xs.svg")} alt="close" />
                                </div>
                            }
                        </div>
                        <Itrack isLink={this.props.quickBuy ? false : true} isFullUrl={true} childOf='' url={this.props.data.url} classname={convertToModule(styles, "cart-item-name")} onClick={this.triggerItemClickEvent}>
                            {this.props.data.catPgDisNm ? this.props.data.catPgDisNm : this.props.data.sv_nm.split(",")[0]}
                            {this.props.data.catPgDisNm ? this.props.data.catPgDisNm : this.props.data.sv_nm.split(",")[0]}
                            <div className={convertToModule(styles, "cart-item__flavour")}>{(this.props.data.sv_nm.split(",")[1]) || " "}</div>
                        </Itrack>
                        {
                            this.state.showLoader &&
                            (isMobile ? <TBSLoader /> : <div className={convertToModule(styles, "hk-loader-default")}><div></div></div>)
                        }
                    </div>
                    {!isMobile &&
                        this.props.data.spCartFreebie &&
                        this.props.data.spCartFreebie.map((e) => {
                            return this.renderBxgyOffer(e);
                        })
                    }
                </IntersectionObserver>
                :
                <IntersectionObserver className={convertToModule(styles, this.props.isCombo ? "cart-item isCombo" : "cart-item")}>

                    {specialOfferID === this.props.data.sv_id && <div className={convertToModule(styles, isMobile ? 'mob special-offer-header' : 'special-offer-header')}>
                        {/* <img className='back-img' src={HK.getImage("/cart/combo-header.svg")} alt="header-img" /> */}
                        <div className={convertToModule(styles, 'heading')}>
                            <div className={convertToModule(styles, 'left')}>
                                <img className={convertToModule(styles, 'percent')} src={HK.getImage("/cart/percentSym.svg")} alt="percent" />
                                <span className={convertToModule(styles, 'text')}>Special Offer Applied</span>
                            </div>
                            <div className={convertToModule(styles, 'right')}>
                                {<span className={convertToModule(styles, 'saving')}>Saved ₹{(this.props.data.totMrp - this.props.data.offerPriceWithoutOffer).toLocaleString()}</span>}
                            </div>
                        </div>
                    </div>}
                    {/* <div className={this.props.isCombo ? "cart-item isCombo" : "cart-item"}> */}
                    <div className={convertToModule(styles, "cart-item__details")}>
                        <Itrack isLink={this.props.quickBuy ? false : true} isFullUrl={true} childOf='' url={this.props.data.url} classname={convertToModule(styles, "cart-item__image gtm_id")} onClick={this.triggerItemClickEvent}>
                            <img name="tumbnail" src={this.props.data.pr_img && this.props.data.pr_img.m_link && this.props.data.pr_img.m_link.replace('http://', 'https://')} alt={this.props.data.pr_img && this.props.data.pr_img.alt} />
                        </Itrack>
                        <div className={convertToModule(styles, "cart-item__desc")}>
                            {
                                isMobile ?
                                    <Itrack isLink={this.props.quickBuy ? false : true} isFullUrl={true} childOf='' url={this.props.data.url} classname={convertToModule(styles, "cart-item__name")} onClick={this.triggerItemClickEvent}>
                                        {this.props.data.catPgDisNm ? this.props.data.catPgDisNm : this.props.data.sv_nm.split(",")[0]},
                                        <div className={convertToModule(styles, "cart-item__flavour")}>{(this.props.data.sv_nm.split(",")[1]) || " "}</div>
                                    </Itrack>
                                    :
                                    <Itrack isLink={this.props.quickBuy ? false : true} isFullUrl={true} childOf='' url={this.props.data.url} classname={convertToModule(styles, "cart-item__name-desktop")} onClick={this.triggerItemClickEvent}>
                                        {this.props.data.catPgDisNm ? this.props.data.catPgDisNm : this.props.data.sv_nm.split(",")[0]},
                                        <span className={convertToModule(styles, "cart-item__flavour")}>{' ' + this.getAttributeStringValue(Object.values(this.props.data.selAttr)[0]) + ` ${this.getAttributeStringValue(Object.values(this.props.data.selAttr)[1]) ? ' | ' : ''} ` + this.getAttributeStringValue(Object.values(this.props.data.selAttr)[1]) || " "}</span>
                                    </Itrack>
                            }
                            {
                                // !this.props.isCombo &&
                                <div className={convertToModule(styles, "cart-item__price")}>

                                    {!(specialOfferID === this.props.data.sv_id) ? <span className={convertToModule(styles, "cart-item__offer-price")}>
                                        ₹{this.props.data.totOffPr.toLocaleString()}
                                    </span>
                                        :
                                        <span className={convertToModule(styles, "cart-item__offer-price")}>
                                            ₹{this.props.data.offerPriceWithoutOffer.toLocaleString()}
                                        </span>
                                    }

                                    {!(specialOfferID === this.props.data.sv_id) ?
                                        this.props.data.totOffPr !== this.props.data.totMrp &&
                                        <span className={convertToModule(styles, "cart-item_total-price")}>
                                            ₹{this.props.data.totMrp.toLocaleString()}
                                        </span>
                                        :
                                        this.props.data.offerPriceWithoutOffer !== this.props.data.totMrp &&
                                        <span className={convertToModule(styles, "cart-item_total-price")}>
                                            ₹{this.props.data.totMrp.toLocaleString()}
                                        </span>
                                    }

                                    {
                                        !(specialOfferID === this.props.data.sv_id) ?
                                            (this.props.data.totMrp - this.props.data.totOffPr) > 0 &&
                                            <span className={convertToModule(styles, "cart-item__discount")}>
                                                Saved ₹{(this.props.data.totMrp - this.props.data.totOffPr).toLocaleString()}
                                            </span>
                                            :
                                            (this.props.data.totMrp - this.props.data.offerPriceWithoutOffer) > 0 &&
                                            <span className={convertToModule(styles, "cart-item__discount")}>
                                                Saved ₹{(this.props.data.totMrp - this.props.data.offerPriceWithoutOffer).toLocaleString()}
                                            </span>
                                    }
                                </div>

                            }
                            {
                                this.props.data && this.props.data.sp_typ !== 2 ?
                                    <div className={`cart_item_tat ${styles['cart_item_tat']} ${isMobile ? ` ${styles['mweb']}` : ``}`}>
                                        <HyperLocalDeliveryWidgit hkrDeliveryResponse={this.props.data.hkrDeliveryResponse || {}} isTimer={true} isFlexWrap={isMobile ? true : false} callBackTatCall={this.handleCallBackTatCall} /></div> : ''

                            }
                            {!HK.isExpressCheckout() && !isMobile && !HK.checkMembershipProduct(this.props.data) && !this.props.isCombo && !this.props.comboGrp &&
                                <div className={convertToModule(styles, this.props.data.vrnt_freebies && this.props.data.vrnt_freebies.length > 0 ? "cart-item__quantity cart-item__quantity-freebie" : "cart-item__quantity")}>

                                    <QuantitySelector quantity={this.state.itemInp} hideLabel
                                        onDecrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty - 1 : this.props.data.qty - 1))}
                                        onIncrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty + 1 : this.props.data.qty + 1))}
                                        removeCart={this.removeCart.bind(this)}
                                        quickBuy={this.props.quickBuy}
                                        dataIndex={this.props.dataIndex}
                                        comboItemLength={this.props.comboItemLength}
                                        handleDeletePopup={this.handleDeletePopup.bind(true)}
                                    />
                                </div>
                            }

                            {!HK.isExpressCheckout() && !HK.checkMembershipProduct(this.props.data) && !isMobile && this.props.renderFooter &&
                                <div className={convertToModule(styles, (this.props.renderFooter ? `cart-item__quantity cart-item__quantity-combo ${this.props.pack && this.props.pack.pack_freebies.length > 0 ? 'cart-item__quantity-freebie extra-top' : ''} ` : "cart-item__quantity "))}>

                                    <QuantitySelector quantity={this.state.itemInp} hideLabel
                                        onDecrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty - 1 : this.props.data.qty - 1))}
                                        onIncrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty + 1 : this.props.data.qty + 1))}
                                        removeCart={this.removeCart.bind(this)}
                                        quickBuy={this.props.quickBuy}
                                        dataIndex={this.props.dataIndex}
                                        comboItemLength={this.props.comboItemLength}
                                        handleDeletePopup={this.handleDeletePopup.bind(true)}
                                    />
                                </div>
                            }
                            {!HK.isExpressCheckout() && !HK.checkMembershipProduct(this.props.data) && isMobile && !this.props.isCombo && this.props.data.varAs != 40 && this.props.data.bxgyOff.length === 0 && <div className={convertToModule(styles, "cart-item__quantity")}>
                                <QuantitySelector quantity={this.state.itemInp} hideLabel
                                    onDecrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty - 1 : this.props.data.qty - 1))}
                                    onIncrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty + 1 : this.props.data.qty + 1))}
                                    removeCart={this.removeCart.bind(this)}
                                    quickBuy={this.props.quickBuy}
                                    dataIndex={this.props.dataIndex}
                                    comboItemLength={this.props.comboItemLength}
                                    handleDeletePopup={this.handleDeletePopup.bind(true)}
                                />
                            </div>}

                        </div>
                        {
                            !HK.isExpressCheckout() && (!this.props.isCombo || (this.props.isCombo && this.props.firstItem)) ?
                                <div className={convertToModule(styles, this.props.isCombo ? "delete-cta delete-combo-icons" : "delete-cta")} onClick={() => this.handleDeletePopup(true)}> <img src={HK.getImage('/icons/delete-new.svg')} alt="black_remove.svg" /> </div>
                                : ''
                        }
                    </div>
                    {!isMobile && this.props.pack && this.props.pack.pack_freebies.length > 0 && <div className={convertToModule(styles, 'freebie_item')}>
                        {this.renderFreebie(this.props.pack.pack_freebies)}
                    </div>}
                    {/* {this.props.renderFooter && !isMobile && this.footerGenerator()} */}

                    {
                        !this.props.isPack &&
                        this.props.data.vrnt_freebies &&
                        this.props.data.vrnt_freebies.length > 0 &&
                        <React.Fragment>
                            {/* {!this.props.isPack &&
                                this.props.data.vrnt_freebies &&
                                this.props.data.vrnt_freebies.length > 0 && <PlusIcon text='Freebies' />} */}
                            <div className={convertToModule(styles, 'freebie_item')}>
                                {this.renderFreebie(this.props.data.vrnt_freebies)}
                            </div>
                        </React.Fragment>

                    }
                    {!isMobile &&
                        this.props.data.spCartFreebie &&
                        this.props.data.spCartFreebie.map((e) => {
                            return this.renderBxgyOffer(e);
                        })
                    }
                    {
                        !isMobile && !this.props.isPack &&
                        this.props.data.bxgyOff &&
                        this.props.data.bxgyOff.length > 0 &&
                        this.props.data.bxgyOff.map((e, i) => {
                            if (i === 0)
                                return (
                                    <div className={convertToModule(styles, "bxgy-off")} key={e.id}>
                                        <span className={convertToModule(styles, " apply-bxgy cart-freebie page-cart-freebie")}>
                                            <span className={convertToModule(styles, "freeBIcon freebgIconURL")}></span>
                                            <div>
                                                {
                                                    this.props.data.spCartFreebie && this.props.data.spCartFreebie.length > 0 ?
                                                        "" :
                                                        <div className={convertToModule(styles, 'applyBogo')}><button className={convertToModule(styles, "bogo")}>Buy One Get One(BOGO) Free</button> <a className={convertToModule(styles, this.props.data.spCartFreebie && this.props.data.spCartFreebie.length > 0 ? "offer-applied" : "")} onClick={this.applyBxgyOffer.bind(this, e)}>{(!this.props.data.spCartFreebie || this.props.data.spCartFreebie.length === 0) && "Apply Offer"} {(this.props.data.spCartFreebie && this.props.data.spCartFreebie.length > 0) && "offer applied"}</a></div>
                                                }
                                            </div>
                                        </span>
                                    </div>
                                );
                            else return "";
                        })
                    }
                    {
                        this.state.showLoader &&
                        (isMobile ? <TBSLoader /> : <div className={convertToModule(styles, "hk-loader-default")}><div></div></div>)
                    }
                    {isMobile && this.props.pack && this.props.pack.pack_freebies.length > 0 && <div className={convertToModule(styles, 'freebie_item')}>
                        {this.renderFreebie(this.props.pack.pack_freebies)}
                    </div>}
                    {!HK.isExpressCheckout() && isMobile && !HK.checkMembershipProduct(this.props.data) && this.props.isCombo && this.props.lastItem && <div className={convertToModule(styles, "cart-item__quantity")}>
                        <QuantitySelector quantity={this.state.itemInp} hideLabel
                            onDecrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty - 1 : this.props.data.qty - 1))}
                            onIncrement={this.updateCart.bind(this, (this.props.isPack ? this.props.pack.qty + 1 : this.props.data.qty + 1))}
                            removeCart={this.removeCart.bind(this)}
                            quickBuy={this.props.quickBuy}
                            dataIndex={this.props.dataIndex}
                            comboItemLength={this.props.comboItemLength}
                            handleDeletePopup={this.handleDeletePopup.bind(true)}
                        />
                        {/* {this.addToWhistList()} */}
                    </div>}
                    {
                        isMobile && !this.props.isPack &&
                        this.props.data.bxgyOff &&
                        this.props.data.bxgyOff.length > 0 &&
                        this.props.data.bxgyOff.map((e, i) => {
                            if (i === 0)
                                return (
                                    <div className={convertToModule(styles, "bxgy-off")} key={e.id}>
                                        <span className={convertToModule(styles, " apply-bxgy cart-freebie page-cart-freebie")}>
                                            <span className={convertToModule(styles, "freeBIcon freebgIconURL")}></span>
                                            <div>
                                                {
                                                    this.props.data.spCartFreebie && this.props.data.spCartFreebie.length > 0 ?
                                                        "" :
                                                        <div className={convertToModule(styles, 'applyBogo')}>
                                                            <button className={convertToModule(styles, "bogo")}>Buy One Get One(BOGO) Free</button>
                                                            <a
                                                                className={convertToModule(styles, this.props.data.spCartFreebie && this.props.data.spCartFreebie.length > 0 ? "offer-applied" : "")}
                                                                onClick={this.applyBxgyOffer.bind(this, e)}>
                                                                {(!this.props.data.spCartFreebie || this.props.data.spCartFreebie.length === 0) && "Apply Offer"} {(this.props.data.spCartFreebie && this.props.data.spCartFreebie.length > 0) && "offer applied"}
                                                            </a>
                                                        </div>
                                                }
                                            </div>
                                        </span>
                                    </div>
                                );
                            else return "";
                        })
                    }
                    {isMobile &&
                        this.props.data.spCartFreebie &&
                        this.props.data.spCartFreebie.map((e) => {
                            return this.renderBxgyOffer(e);
                        })
                    }

                    {
                        this.state.isDeletePopup &&
                        <ConfirmModal handleDeletePopup={this.handleDeletePopup} src={this.props.data.pr_img && this.props.data.pr_img.t_link && this.props.data.pr_img.t_link.replace('http://', 'https://')} removeCart={this.removeCart.bind(this)} moveToWishList={this.moveToWishList.bind(this)} />
                    }
                </IntersectionObserver>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _cartOrderSummaryAction: cartOrderSummaryAction,
        _cartVariantAction: cartVariantAction,
        _cartPackAction: cartPackAction,
        _cartOfferAction: cartOfferAction,
        _cartAppliedOfferAction: cartAppliedOfferAction,
        _isLoginPopupAction: isLoginPopupAction,
        _CARTAction: CARTAction,
        _headerText: headerTextAction,
        _codAllowedAction: codAllowedAction,
        _paymentPrepareAction: paymentPrepareAction,
        _headerMenuAction: headerMenuAction,
        _userRoleAction: userRoleAction,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        _cartVariantReducer: state.cartVariantReducer,
        _cartOrderSummaryReducer: state.cartOrderSummaryReducer,
        _cartAppliedOfferReducer: state.cartAppliedOfferReducer,
    };
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CartItem)
);