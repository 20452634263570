import { convertToModule } from 'factory/utils/clientUtils';
import styles from './bogo-item.module.scss';
import React from 'react';
import HK from 'factory/modules/HK';
import PlusIcon from '../../plusIcon';
import Link from 'components/Assets/LinkHref';
import QuantitySelector from 'components/Pdp/common/quantitySelector/QuantitySelector';
const BogoItem = ({ data, removeBxgyOffer, parentState, onDecrement, onIncrement, removeCart, parentProps, desktopCartMenu }) => {

    const renderRemoveBXGYBtn = () => (
        parentProps && parentProps.data && parentProps.data.spCartFreebie && parentProps.data.spCartFreebie.length > 0 ? <>
            {
                parentProps.data.spCartFreebie.map((data, i) => {
                    return (
                        <div key={`spcfb_${i}`} className={convertToModule(styles, "bogo__cta")}>
                            <button className={convertToModule(styles, "bogo")}>
                                Buy One Get One(BOGO) Free
                            </button>
                            <a
                                data-wishlist-role=""
                                data-role="item-remove"
                                data-wishlist-cart-id="14639"
                                onClick={removeBxgyOffer}
                                className={convertToModule(styles, "remove_offer")}
                            >
                                Remove Offer
                            </a>
                        </div>
                    );
                })
            }
        </> : <></>
    );
    const altMsg= data && data.pr_img && data.pr_img.alt;
    const imgUrl =data && data.pr_img && data.pr_img.t_link && data.pr_img.t_link;
    return (
        <div className={convertToModule(styles, "bogo__container")}>
            <PlusIcon text='Buy One Get One (BOGO) Free' />
            {desktopCartMenu ?
                <div className={convertToModule(styles, "cartitem-list")}>
                    <div className={convertToModule(styles, "cart-image")}>
                        <img name="tumbnail" src={(imgUrl||'').replace('http://', 'https://')} alt={altMsg} className={convertToModule(styles, "prod-image")} />
                    </div>
                    <Link className={convertToModule(styles, "cart-item-name")} to={data.url}>
                        {data.catPgDisNm ? data.catPgDisNm : data.sv_nm.split(",")[0]}
                        <div className={convertToModule(styles, "cart-item__flavour")}>{(data.sv_nm.split(",")[1]) || " "}</div>
                    </Link>
                </div>
                :
                <div className={convertToModule(styles, "cart-item")}>
                    <div className={convertToModule(styles, "cart-item__details")}>
                        <Link className={convertToModule(styles, "cart-item__image gtm_id")} to={data.url}>
                            <img name="tumbnail" src={(imgUrl||'').replace('http://', 'https://')} alt={altMsg} />
                        </Link>
                        <div className={convertToModule(styles, "cart-item__desc")}>
                            <Link to={data.url} className={convertToModule(styles, "cart-item__name")}>
                                {data.catPgDisNm ? data.catPgDisNm : data.sv_nm.split(",")[0]}
                                <div className={convertToModule(styles, "cart-item__flavour")}>{(data.sv_nm.split(",")[1]) || " "}</div>
                            </Link>
                            {
                                data &&
                                data.hkrDeliveryResponse &&
                                data.hkrDeliveryResponse.estDeliveryDate &&
                                <div className={convertToModule(styles, "cart-item__date")}>{`Delivery by ${HK.formatDate(data.hkrDeliveryResponse.estDeliveryDate, 'new').f4}`}</div>
                            }
                        </div>
                    </div>
                    {
                        !HK.isMobile() &&
                        renderRemoveBXGYBtn()
                    }
                    {HK.isMobile() &&
                        <div className={convertToModule(styles, 'bogocart')}>
                            <QuantitySelector
                                quantity={parentState.itemInp}
                                hideLabel
                                onDecrement={onDecrement}
                                onIncrement={onIncrement}
                                removeCart={removeCart}
                            />
                        </div>
                    }
                    {
                        HK.isMobile() &&
                        renderRemoveBXGYBtn()
                    }
                </div>
            }
        </div>
    );
};

export default BogoItem;