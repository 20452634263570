import React from 'react';
import HK from '../../../factory/modules/HK';
import CartItem from '../CartItem';
import PlusIcon from '../plusIcon';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from './cartComboItem.module.scss';
const CartComboItem = ((props) => {
    let countIt = 0;
    const offerPrice = props.data && props.data.totOffPr || 0;
    const totalMRP = props.data && props.data.totMrp || 0;
    const totalSaving = totalMRP - offerPrice;

    const headerGenerator = () => {
        return (
            <div className={convertToModule(styles, 'combo-header')}>
                {!HK.isMobile() && <img src={HK.getImage("/cart/combo-strip-background.svg")} alt="header-img" />}
                <div className={convertToModule(styles, 'heading')}>
                    <img src={HK.getImage('/cart/Partying_Face.png')} alt="party-image" className={convertToModule(styles, 'msg-img')} />
                    <span className={convertToModule(styles, 'msg')}>{`Combo added, you have saved ₹${totalSaving.toLocaleString()}`}</span>
                </div>
            </div>
        );
    };
    return (
        <div className={convertToModule(styles, props.menuActual ? "cart-pack-item side-menu-actual" : "cart-pack-item")}>
            <div className={convertToModule(styles, props.menuActual ? "cartPackChild combo-item-from-side-menu" : "cartPackChild")}>
                {headerGenerator()}
                {
                    props.data.variants &&
                    props.data.variants.map((e, j) => {
                        // if (j === props.data.variants.length - 1) {
                        if (j === 0) {

                            return (<div key={`crtv_${j}`} className={convertToModule(styles, 'cartPlus-combo')}>
                                <CartItem menuActual={props.menuActual} dataIndex={j} data={{
                                    ...e, ...{
                                        // loyaltyCash: props.data.pack_total_loyalty_cash,
                                        // totMrp: props.data.totMrp,
                                        // totOffPr: props.data.totOffPr,
                                        qty: props.data.qty,
                                    }
                                }} key={countIt++} firstItem={true} lastItem={props.data.variants.length - 1 === 0 ? true : false} isCombo={true} comboGrp={true} isQty={true} isPack={true} pack={props.data} desktopCartMenu={props.desktopCartMenu} comboItem={props.comboItem} quickBuy={props.quickBuy} renderFooter={props.data.variants.length - 1 === 0} />
                                {props.data.variants.length - 1 === 0 ? '' : <PlusIcon menuActual={props.menuActual} />}
                            </div>
                            );
                        } else {
                            return (
                                <div key={`crtv_${j}`} className={convertToModule(styles, 'cartPlus-combo')}>
                                    <CartItem menuActual={props.menuActual} dataIndex={j} data={{
                                        ...e, ...{
                                            // loyaltyCash: props.data.pack_total_loyalty_cash,
                                            // totMrp: props.data.totMrp,
                                            // totOffPr: props.data.totOffPr,
                                            qty: props.data.qty
                                        }
                                    }} key={countIt++} firstItem={false} lastItem={j === props.data.variants.length - 1} isCombo={true} comboGrp={true} isQty={true} isPack={true} pack={props.data} desktopCartMenu={props.desktopCartMenu} comboItem={props.comboItem} quickBuy={props.quickBuy} renderFooter={j === props.data.variants.length - 1} />
                                    {j !== props.data.variants.length - 1 &&
                                        <PlusIcon menuActual={props.menuActual} />
                                    }
                                </div>
                            );
                        }
                    })
                }
                {props.menuActual && <div className={convertToModule(styles, props.menuActual ? "freebie-div side-menu-cart" : "freebie-div")} data-role="freebie-item" data-freebie-id="1865">
                    {
                        props.data.pack_freebies &&
                        props.data.pack_freebies.map((e, index) => {
                            return (
                                <span key={`${e.freebieId}_${index}`} className={convertToModule(styles, "cart-freebie")}>
                                    Free {e.freebieNm}
                                    (Quantity: <span data-role="vrnt-freebie-qty">{e.qty}</span>)
                                    {/* <FreebieItem data={e} key={index} /> */}
                                </span>
                            );
                        })
                    }
                </div>}
                {(props.data && props.data.totOffPr) && !props.menuActual ? <div className={convertToModule(styles, props.data.pack_freebies && props.data.pack_freebies.length > 0 ? "total-for-pack total-for-pack-with-freebie " : "total-for-pack")}>Total :<span className={convertToModule(styles, "total-for-pack-amnt")}> ₹{props.data.totOffPr}</span></div>:''}
            </div>
        </div>
    );
});

export default CartComboItem;